import React from 'react'
import { HomeInfo, NavBar, Footer } from '../component'
import { motion } from 'framer-motion'
import {  textPop } from '../animations/index';

const Home = () => {
  return (
    <motion.main {...textPop} className='w-screen min-h-screen flex items-center justify-start flex-col bg-primary'>
        <NavBar />
        <div>
            <HomeInfo />
        </div>
        <Footer />
    </motion.main>
  )
}

export default Home